@font-face {
  font-family: 'icomoon';
  src:
  url('fonts/icomoon.ttf?db0c29') format('truetype'),
  url('fonts/icomoon.woff?db0c29') format('woff'),
  url('fonts/icomoon.svg?db0c29#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tick:before {
  content: "\e932";
}
.icon-checked:before {
  content: "\e932";
}
.icon-audio:before {
  content: "\e930";
}
.icon-whatsapp:before {
  content: "\e927";
}
.icon-sun:before {
  content: "\e91a";
}
.icon-youtube:before {
  content: "\e92c";
}
.icon-home:before {
  content: "\e91b";
}
.icon-image:before {
  content: "\e91c";
}
.icon-calendar:before {
  content: "\e91d";
}
.icon-bubble:before {
  content: "\e91e";
}
.icon-quotes-left:before {
  content: "\e91f";
}
.icon-quotes-right:before {
  content: "\e920";
}
.icon-stats-bars:before {
  content: "\e921";
}
.icon-ai_audio:before {
  content: "\e931";
}
.icon-email:before {
  content: "\e92e";
}
.icon-exit_to_app:before {
  content: "\e900";
}
.icon-logout:before {
  content: "\e901";
}
.icon-create:before {
  content: "\e902";
}
.icon-bedtime:before {
  content: "\e903";
}
.icon-error_outline:before {
  content: "\e904";
}
.icon-video:before {
  content: "\e905";
}
.icon-web:before {
  content: "\e906";
}
.icon-location:before {
  content: "\e907";
}
.icon-add_circle_outline:before {
  content: "\e908";
}
.icon-clear:before {
  content: "\e909";
}
.icon-navigate_before:before {
  content: "\e90a";
}
.icon-navigate_next:before {
  content: "\e90b";
}
.icon-arrow_back:before {
  content: "\e90c";
}
.icon-arrow_drop_down:before {
  content: "\e90d";
}
.icon-arrow_drop_up:before {
  content: "\e90e";
}
.icon-arrow_forward:before {
  content: "\e90f";
}
.icon-expand_less:before {
  content: "\e910";
}
.icon-expand_more:before {
  content: "\e911";
}
.icon-arrow_upward:before {
  content: "\e912";
}
.icon-arrow_downward:before {
  content: "\e913";
}
.icon-arrow_left:before {
  content: "\e914";
}
.icon-arrow_right:before {
  content: "\e915";
}
.icon-bookmark:before {
  content: "\e916";
}
.icon-bookmark_outline:before {
  content: "\e917";
}
.icon-search:before {
  content: "\e918";
}
.icon-share:before {
  content: "\e919";
}
.icon-webinar:before {
  content: "\e922";
}
.icon-menu:before {
  content: "\e923";
}
.icon-arrow_first:before {
  content: "\e92d";
}
.icon-arrow_last:before {
  content: "\e924";
}
.icon-clean_circle:before {
  content: "\e925";
}
.icon-user:before {
  content: "\e926";
}
.icon-x:before {
  content: "\e92f";
}
.icon-facebook:before {
  content: "\e928";
}
.icon-rss:before {
  content: "\e929";
}
.icon-linkedin:before {
  content: "\e92a";
}
.icon-wechat:before {
  content: "\e92b";
}
