@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* .paywall-text-heading {
    @apply  tw-text-lg sm:tw-text-2xl lg:tw-text-3xl;
  }

  .paywall-text-body {
    @apply  tw-text-base sm:tw-text-lg lg:tw-text-xl;
  } */
}


[data-app-version]:after {
  content: attr(data-app-version);
  font-size: 0.6rem;
  display: inline;
  transform: translate(-0.5rem, -1.5rem);
  position: absolute;
  opacity: 0.2;
  word-break: break-all;
  background-color: white;
  padding: 2px;
  right: 0;
}


.ArticleImage {
  margin: 1.2em;
  margin-left: 0;
  margin-right: 0;
}

.ArticleImage[style*="text-align:center"],
.ArticleImage[style*="text-align: center"] {
  margin: 1.2em;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
}

.ArticleImage[style*="text-align:right"],
.ArticleImage[style*="text-align: right"] {
  margin: 1.2em;
  margin-left: 0;
  margin-right: 0;
  text-align: right;
}

.ArticleImage[style*="text-align:center"] img,
.ArticleImage[style*="text-align: center"] img,
.ArticleImage[style*="text-align:right"] img,
.ArticleImage[style*="text-align: right"] img {
  display: inline;
}

.ArticleImage[style*="float:left"],
.ArticleImage[style*="float: left"] {
  margin: 1.2em;
  margin-left: 0;
}

.ArticleImage[style*="float:right"],
.ArticleImage[style*="float: right"] {
  margin: 1.2em;
  margin-right: 0;
}

.ArticleImageCaption {
  @apply tw-text-border;
  font-size: .75em;
}

.tw-prose :where(blockquote p strong):not(:where([class~="not-prose"] *)), 
.tw-prose :where(blockquote p b):not(:where([class~="not-prose"] *)) {
  @apply tw-font-black;
}

.tw-prose :where(blockquote):not(:where([class~="not-prose"] *))::before {
  content: 'quotes-left';
  font-family: 'icomoon';
  font-size: 3rem;
  line-height: 1;
  position: absolute;
  top: 0.4em;
  left: 0;
}

@media (min-width: 768px) {
  .tw-prose :where(blockquote):not(:where([class~="not-prose"] *))::before {
    font-size: 4.5rem;
    top: 0.3em;
  }
}

@media (min-width: 1024px) {
  .tw-prose :where(blockquote):not(:where([class~="not-prose"] *))::before {
    font-size: 6rem;
    top: 0.2em;
  }
}

.tw-prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"]) *)::before,
.tw-prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"]) *)::after {
    content: none;
}

[class*="geotargetlygeocontent"]:not([style*="none"]) {
  display: grid !important;
}

.shorthand-embed > p:first-child, .shorthand-embed > [data-shorthand], .shorthand-embed > [data-shorthand] + p:last-child {
  display: none; 
}

.figureSide + [class*="space"] {
  @apply !tw-pt-4;
}

.adSlot:empty {
  @apply tw-hidden;
}

/* .adSlot.native {
  @apply before:!tw-hidden after:!tw-hidden;
} */

.adSlot > * {
  @apply !tw-block;
}

.interscroller {
  clip-path: inset(0);
}

/* aside section:empty + div.shortcode-space,
section:empty + div.tw-hidden.sm\:tw-block > .shortcode-space:first-child,
section:empty + div.tw-hidden.sm\:tw-block > .shortcode-space:first-child + .shortcode-space {
  display: none;
} */

[data-shortcode='Title'] + [data-shortcode],
[data-shortcode='Button'] + [data-shortcode] {
  @apply tw-mt-8;
}

[data-shortcode] + [data-shortcode='Title'] {
  @apply tw-mt-16;
}

[data-shortcode='Title'] {
  @apply first:!tw-mt-0;
}

[data-shortcode*='AsideArticleSummary'][class*="geotargetlygeocontent"][style*="none"] + [data-shortcode='Border'] {
  display: none;
}

[data-shortcode='AsideArticleSummary'] + [data-shortcode='AsideArticleSummary'],
[data-shortcode='AsideArticleSummary'] + [data-shortcode='FigureWithAsideArticleSummary'] {
  @apply tw-mt-4 tw-pt-4 tw-border-t tw-border-sideBorder;
}

[data-shortcode='FigureWithAsideArticleSummary'] + [data-shortcode='AsideArticleSummary'] {
  @apply tw-mt-4;
}

[data-shortcode='AsideArticleSummary-center'] + [data-shortcode='AsideArticleSummary-center'],
[data-shortcode='FigureWithAsideArticleSummary-center'] + [data-shortcode='AsideArticleSummary-center'],
[data-shortcode='AsideArticleSummary-center'] + [data-shortcode='FigureWithAsideArticleSummary-center'] {
  @apply tw-mt-4 md:tw-mt-8 tw-pt-4 md:tw-pt-8 tw-border-t tw-border-border;
}

[data-shortcode='Button'] {
  @apply tw-mt-8;
}

section:empty + .tw-home-space {
  display: none;
}

.homeRightColumn > :empty {
  display: none;
}

div[id*='google_ads'] {
  @apply !tw-block !tw-w-auto !tw-h-auto;
}

iframe[id*='google_ads'] {
  @apply tw-mx-auto;
}

.categoryNativeAd > * {
  display: block !important;
}